import NavigationBar from "../navigation/NavigationBar";
import IInteractiveDemoViewManager from "./interfaces/IInteractiveDemoViewManager";
import "./InteractiveDemoViewManager.css";

declare const process: {
  env: {
    REACT_APP_STORE_PORTAL_URL: string;
    REACT_APP_ADMIN_PORTAL_URL: string;
    REACT_APP_WAREHOUSE_PORTAL_URL: string;
    REACT_APP_PORTAL_TITLE: string;
  };
};

export default function InteractiveDemoViewManager(props: IInteractiveDemoViewManager): JSX.Element {
  return (
    <div className="interactive-demo-container">
      <div className="interactive-demo-portals-container">
        <div className="interactive-demo-right-vertical-container">
          <iframe src={process.env.REACT_APP_ADMIN_PORTAL_URL} className="interactive-demo-admin-frame" title="Admin Demo"></iframe>
          <iframe src={process.env.REACT_APP_WAREHOUSE_PORTAL_URL} className="interactive-demo-warehouse-frame" title="Warehouse Demo"></iframe>
        </div>
        <NavigationBar portalName={process.env.REACT_APP_PORTAL_TITLE} />
      </div>
    </div>
  );
}
