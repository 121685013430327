import "./App.css";
import { Routes, Route } from "react-router-dom";
import DefaultDemoViewManager from "./portal/views/DefaultDemoViewManager";
import InteractiveDemoViewManager from "./portal/views/InteractiveDemoViewManager";
import { GetPortalConfigurationFromEnvironmentFile } from "./helpers/ConfigurationHelper";

const portalConfiguration = GetPortalConfigurationFromEnvironmentFile();

document.title = portalConfiguration.PortalTitle;

function App(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<DefaultDemoViewManager />} />
      <Route path="/interactive" element={<InteractiveDemoViewManager />} />
    </Routes>
  );
}

export default App;
