import NavigationBar from "../navigation/NavigationBar";
import IDefaultDemoViewManager from "./interfaces/IDefaultDemoViewManager";
import "./DefaultDemoViewManager.css";
import { GetPortalConfigurationFromEnvironmentFile } from "../../helpers/ConfigurationHelper";

const portalConfiguration = GetPortalConfigurationFromEnvironmentFile();

export default function DefaultDemoViewManager(props: IDefaultDemoViewManager): JSX.Element {
  return (
    <div className="default-demo-container">
      <div className="default-demo-store-container">
        <iframe src={portalConfiguration.StorePortalUrl} className="default-demo-store-frame" title="Store Demo"></iframe>
        <NavigationBar portalName={portalConfiguration.PortalTitle} />
      </div>
      <div className="default-demo-right-vertical-container">
        <iframe src={portalConfiguration.AdminPortalUrl} className="default-demo-admin-frame" title="Admin Demo"></iframe>
        <iframe src={portalConfiguration.WarehousePortalUrl} className="default-demo-warehouse-frame" title="Warehouse Demo"></iframe>
      </div>
    </div>
  );
}
